import styled from "styled-components";

export const ArticleListStyles = styled.div`
  height: 900vh;
  width: 100%;
  overflow: hidden;

  .scrollmagic-pin-spacer {
    position: static !important;
    padding: 0 !important;
    left: 0 !important;
  }

  @media only screen and (max-width: 1079px) {
    height: auto;
    padding: 50px 0;
    @media only screen and (max-width: 767px) {
      padding: 30px 0;
    }
    .article-list,
    .scrollmagic-pin-spacer,
    .scroll-y {
      position: static !important;
      height: auto !important;
      padding: 0 !important;
      display: block !important;
      width: 100% !important;
    }
  }

  .article-list {
    width: 100%;
    height: 100vh;
    left: 0 !important;
  }

  .scroll-y {
    position: relative;
    height: 100vh;
    width: 900vw;
    display: flex;
    overflow-x: hidden;
  }

  .article-item {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;

    @media only screen and (max-width: 1079px) {
      height: auto;
    }

    &:first-child {
      .inner {
        margin-top: 50px;
      }
    }

    .inner {
      position: relative;
      display: flex;
      align-items: flex-start;
      max-width: 100%;
      min-height: 535px;
      top: -70px;

      @media only screen and (max-width: 1079px) {
        display: block;
        margin: 175px auto 0;
        min-height: 0;
      }

      @media only screen and (max-width: 767px) {
        margin: 94px auto 0;
        top: 0;
      }

      .image-container {
        img {
          width: 688px;
          height: auto;
          display: block;

          @media only screen and (max-width: 1399px) {
            width: 513px;
          }
          @media only screen and (max-width: 1079px) {
            width: 623px;
          }
          @media only screen and (max-width: 767px) {
            width: 100%;
            padding: 0 20px;
          }
        }
      }

      .rel {
        position: relative;
        @media only screen and (max-width: 767px) {
          width: 100%;
          padding: 0 20px;
        }
      }

      .iframe-container {
        position: relative;
        max-width: 100%;
        width: 688px;
        height: 0;
        padding-bottom: 73.5%;
        overflow: hidden;
        @media only screen and (max-width: 1399px) {
          width: 513px;
        }
        @media only screen and (max-width: 1079px) {
          width: 623px;
        }
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
        iframe,
        video {
          position: absolute;
          top: 0;
          left: 50%;
          width: 137%;
          height: 100%;
          border: 0;
          transform: translateX(-50%);
        }
      }

      .content {
        padding-left: 60px;

        @media only screen and (max-width: 1699px) {
          padding-left: 55px;
        }

        @media only screen and (max-width: 1439px) {
          padding-left: 50px;
        }

        @media only screen and (max-width: 1079px) {
          padding-left: 0;
        }
        @media only screen and (max-width: 767px) {
          padding-left: 0;
        }
      }

      h2 {
        color: #1f00b7;
        width: 609px;
        font-size: 100px;
        font-weight: 300;
        line-height: 100px;
        letter-spacing: -0.03em;
        margin-top: 18px;
        margin-bottom: 50px;
        margin-left: -142px;
        margin-left: 0;
        position: relative;

        @media only screen and (max-width: 1439px) {
          font-size: 80px;
          line-height: 80px;
          width: 470px;
          margin-left: -124px;
          margin-left: 0;
        }

        @media only screen and (max-width: 1079px) {
          width: 490px;
          margin-left: 0;
          margin-top: 0;
          font-size: 80px;
          line-height: 80px;
          margin-bottom: 25px;
        }

        @media only screen and (max-width: 767px) {
          max-width: 370px;
          width: 100%;
          padding: 0 20px;
          margin-top: 12px;
          font-size: 50px;
          line-height: 50px;
        }
      }

      .excerpt {
        width: 395px;
        font-family: "Tiempo";
        font-size: 18px;
        font-weight: 400;
        line-height: 29px;
        margin: 0;
        padding: 0;

        @media only screen and (max-width: 1439px) {
          font-size: 16px;
          line-height: 25px;
          width: 345px;
        }

        @media only screen and (max-width: 1079px) {
          margin-left: 90px;
        }

        @media only screen and (max-width: 767px) {
          width: 100%;
          padding: 0 20px;
          margin-left: 0;
        }
      }

      .pill {
        color: #1f00b7;
        display: table;
        width: auto;
        padding: 0 14px;
        height: 28px;
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
        border: 1px solid #1f00b7;
        border-radius: 20px;
        margin-bottom: 13px;

        @media only screen and (max-width: 1079px) {
          margin-left: 90px;
        }

        @media only screen and (max-width: 767px) {
          margin-left: 20px;
        }
      }

      .link {
        display: inline-flex;
        color: #1f00b7;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        margin-top: 32px;

        @media only screen and (max-width: 1079px) {
          margin-left: 90px;
          margin-top: 20px;
        }

        @media only screen and (max-width: 767px) {
          margin-left: 20px;
        }

        img {
          position: relative;
          left: 0;
          display: inline-block;
          margin-left: 9px;
          transition: 0.25s ease all;
        }
        &:hover {
          img {
            left: 5px;
          }
        }
      }
    }
  }
`;
