import Link from "next/link";
import { Tween } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";
import Delayed from "../Delayed";
import VisuallyHidden from "../VisuallyHidden";
import { ArticleListStyles } from "./ArticleListStyles";

const ArticleList = ({ articles }) => {
  return (
    <ArticleListStyles id="stories">
      <Controller>
        <div id="sticky-articles-trigger" />
        <div id="scrolly-trigger" />
        <Scene duration="810%" triggerElement="#sticky-articles-trigger" triggerHook="onLeave" pin>
          <div className="article-list">
            <Scene duration="800%" triggerElement="#scrolly-trigger" triggerHook="onLeave">
              <Tween
                to={{
                  left: "-800%",
                }}
              >
                <div className="scroll-y">
                  {articles.map((item) => (
                    <div className="article-item" key={item.title}>
                      <div className="inner">
                        {item.vimeoId && item.vimeoId !== "gif" ? (
                          <div className="rel">
                            <div className="iframe-container">
                              <Delayed>
                                <iframe
                                  loading="lazy"
                                  src={item.videoUrl}
                                  width="426"
                                  height="240"
                                  frameBorder="0"
                                  allow="autoplay; fullscreen; picture-in-picture"
                                  allowFullScreen=""
                                  title={item.title}
                                />
                              </Delayed>
                            </div>
                          </div>
                        ) : (
                          <div className="image-container">
                            <img width="500" height="338" src={item.image} alt={item.imageAlt} loading="lazy" />
                          </div>
                        )}
                        <div className="content">
                          <h2 className="title" dangerouslySetInnerHTML={{ __html: item.title}} />
                          <div className="pill">{item.pill}</div>
                          <p className="excerpt" dangerouslySetInnerHTML={{ __html: item.excerpt}} />
                          <Link href={item.href} scroll={false} legacyBehavior>
                            <a className="link">
                              <span>READ MORE</span>
                              <img width="43" height="18" src="/blue-arrow.svg" alt="Read more arrow" loading="lazy" />
                              <VisuallyHidden>Read more about {item.title}</VisuallyHidden>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Tween>
            </Scene>
          </div>
        </Scene>
      </Controller>
    </ArticleListStyles>
  );
};

export default ArticleList;
